import React, { useState, useEffect } from "react";
import './AccueilAssociation.css';
import { Link } from 'react-router-dom';

const AccueilAssociation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="aa-two-column-section">
      <div className="aa-innerContainer">
        <div className="aa-left-column">
          <h2 className="aa-titre">
            Une histoire de courage et de solidarité
          </h2>
          <p className="aa-textArea">
            L'association La route à bout de bras (RBB) est née sous l'impulsion de Mamadou Sow, réfugié handicapé des jambes qui a surmonté d'immenses difficultés pour venir de Guinée en Europe à la force des bras. 
            Après avoir publié un livre racontant sa vie à Conakry et son dangereux voyage, Mamadou Sow a voulu venir en aide à des personnes à mobilité réduite en Guinée. Depuis Aoste, en Italie, où il réside actuellement, il a alors créé en Guinée l'Association de soutien aux personnes vivant avec un handicap (ASPH). Elle est le principal partenaire de l'association La route à bout de bras (RBB).
          </p>
        </div>
        <div className="aa-right-column">
          <div className="aa-videoContainer">
          <video
            title="Vidéo de nos activités"
            src="./CourageSolidarite_short.mp4"
            controls
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          >
            Votre navigateur ne supporte pas la lecture de vidéos.
          </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccueilAssociation;