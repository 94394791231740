// Soutien.js
import React from 'react';
import './Soutiens.css';

const Soutiens = () => {
  return (
    <div className="soutien-container">
      <h2>Nos Soutiens</h2>
      Merci du fond du cœur :

      <ul>
        <li>aux membres dévoués de notre association</li>
        <li>aux généreux donateurs privés</li>
      </ul>

      Et un merci particulier à nos partenaires financiers :
      <ul className="partenaires-list">
        <li>Fondation « Plein-Vent » Emile, Marthe et Charlotte E. Rüphi</li>
        <li>Fondation Rösch</li>
        <li>Fondation Pro Victimis</li>
        <li>Fondation Lord Michelham of Hellingly</li>
        <li>Evangelical Lutheran Church of Geneva</li>
        <li>Degonda Rehab S.A</li>
      </ul>
    </div>
  );
}

export default Soutiens;
